import { DEFAULT_PAGE } from "@/shared/constants/setting";
import { ErrorData } from "@/shared/interfaces";
import { ISettingState } from "@/shared/interfaces/setting";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { customerActions } from "./actions/customerActions";
import { depotActions } from "./actions/depotActions";
import { integrationSetupActions } from "./actions/integrationSetupActions";
import { reasonActions } from "./actions/reasonActions";
import { routerConfigActions } from "./actions/routerConfigActions";
import { routeRuleActions } from "./actions/routeRuleActions";
import { userActions } from "./actions/userActions";
import { vehicleActions } from "./actions/vehicleActions";
import { vehicleVariantActions } from "./actions/vehicleVariantActions";

const initialState: ISettingState = {
  loading: false,
  routerConfigs: [],
  vehicles: [],
  vehicle: undefined,
  vehicleVariants: [],
  vehicleVariant: undefined,
  routerConfig: undefined,
  suggestions: {
    vehicles: [],
    vehicleVariants: [],
    depots: [],
  },
  reasons: [],
  reason: undefined,
  depots: [],
  users: [],
  routeRules: [],
  routeRule: undefined,
  customers: [],
  customer: undefined,
  integrationSetups: [],
  integrationSetup: undefined,
  vehicleTrackingSettings: undefined,
  user: undefined,
  depot: undefined,
  error: undefined,
  success: undefined,
  totalPages: DEFAULT_PAGE,
};

const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    ...routerConfigActions,
    ...vehicleActions,
    ...reasonActions,
    ...depotActions,
    ...userActions,
    ...routeRuleActions,
    ...customerActions,
    ...vehicleVariantActions,
    ...integrationSetupActions,
    resetData: () => initialState,
    resetMessage: (state: ISettingState) => {
      state.error = undefined;
      state.success = undefined;
    },
    requestFailed: (state: ISettingState, action: PayloadAction<ErrorData | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Setting actions
export const settingActions = settingSlice.actions;

// Setting reducer
export default settingSlice.reducer;
