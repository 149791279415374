import axiosClient from "@/core/axios";
import { IResponse } from "@/shared/interfaces";
import { IVehicle } from "@/shared/interfaces/setting";

const vehicleApi = {
  getVehicles: (): Promise<IResponse<IVehicle>> => {
    const url = "/vehicles";
    return axiosClient.get(url);
  },
  createVehicle: (data: any): Promise<IResponse<any>> => {
    const url = "/vehicles";
    return axiosClient.post(url, data);
  },
  getVehicle: (id: number): Promise<IResponse<any>> => {
    const url = `/vehicles/${id}`;
    return axiosClient.get(url);
  },
  updateVehicle: (id: number, data: any): Promise<IResponse<any>> => {
    const url = `/vehicles/${id}`;
    return axiosClient.patch(url, data);
  },
  getVehicleVariants: (): Promise<IResponse<any>> => {
    const url = "/vehicle-variants";
    return axiosClient.get(url);
  },
  getVehicleVariant: (id: number): Promise<IResponse<any>> => {
    const url = `/vehicle-variants/${id}`;
    return axiosClient.get(url);
  },
  getVehicleTrackingSettings: (): Promise<IResponse<any>> => {
    const url = `/vehicles/tracking-settings`;
    return axiosClient.get(url);
  },
  createVehicleVariant: (data: any): Promise<IResponse<any>> => {
    const url = "/vehicle-variants";
    return axiosClient.post(url, data);
  },
  removeVehicleVariant: (id: number): Promise<IResponse<any>> => {
    const url = `/vehicle-variants/${id}`;
    return axiosClient.delete(url);
  },
  updateVehicleVariant: (id: number, data: any): Promise<IResponse<any>> => {
    const url = `/vehicle-variants/${id}`;
    return axiosClient.patch(url, data);
  },
  updateVehicleTrackingSettings: (data: any): Promise<IResponse<any>> => {
    const url = `/vehicles/tracking-settings`;
    return axiosClient.patch(url, data);
  },
};

export default vehicleApi;
